import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col, Box, Typography, Breakpoint } from '@smooth-ui/core-sc';
import BlockContent from 'components/block-content';
import Button from 'components/Button';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Contact from 'components/Forms/Contact';

// Context
import UiContext from 'context/UiContext';

export const query = graphql`
  query CreditPageQuery {
    sanityCredit {
      hypo_title
      hypo_button
      hypo_button_url
      _rawHypoBody
      hypo_creditLogos {
        asset {
          url
        }
      }
      consumer_title
      consumer_button
      consumer_button_url
      _rawConsumerBody
      consumer_creditLogos {
        asset {
          url
        }
      }
      contact {
        title
        image {
          asset {
            fixed(toFormat: JPG, width: 565, height: 750) {
              src
            }
          }
        }
      }
      seo {
        title
        description
        image {
          asset {
            fixed(toFormat: JPG, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`;

const Credit = ({ data }) => {
  const page = data.sanityCredit;

  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image.asset.fixed.src}
      />
      <UiContext.Consumer>
        {({ isIE }) => (
          <Grid mb={{ xs: 6, md: 18 }}>
            <Breakpoint up="md">
              <Row mb={{ md: 6, lg: 12 }}>
                <Col md={6}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    height={1}
                    py={{ md: 4, lg: 9 }}
                    px={{ md: 7, lg: 14 }}
                    backgroundColor="primary"
                    textAlign="center"
                  >
                    <Typography color="green" variant="h1">
                      {page.hypo_title}
                    </Typography>
                    {page.hypo_button && page.hypo_button_url && (
                      <Button
                        color="green"
                        brdrColor="green20"
                        accent="brick"
                        as="a"
                        mt={3}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={page.hypo_button_url}
                      >
                        {page.hypo_button}
                      </Button>
                    )}
                  </Box>
                </Col>
                <Col md={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    height={1}
                    py={9}
                    px={14}
                    backgroundColor="primary"
                    textAlign="center"
                  >
                    <Typography color="green" variant="h1">
                      {page.consumer_title}
                    </Typography>
                    {page.consumer_button && page.consumer_button_url && (
                      <Button
                        mt={3}
                        color="green"
                        brdrColor="green20"
                        accent="brick"
                        as="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={page.consumer_button_url}
                      >
                        {page.consumer_button}
                      </Button>
                    )}
                  </Box>
                </Col>
              </Row>
              <Row gutter={0}>
                <Col mb={{ md: 5, lg: 11 }} gutter={0} borderRight="1px solid">
                  <Box px={{ md: 7, lg: 10 }}>
                    <BlockContent blocks={page._rawHypoBody || []} />
                  </Box>
                </Col>
                <Col mb={{ md: 5, lg: 11 }} gutter={0}>
                  <Box px={{ md: 7, lg: 10 }}>
                    <BlockContent blocks={page._rawConsumerBody || []} />
                  </Box>
                </Col>
              </Row>
              <Row gutter={0}>
                <Col md={6} gutter={0}>
                  <Box
                    px={1}
                    py={9}
                    backgroundColor="white"
                    display={isIE ? 'flex' : 'grid'}
                    borderRight="1px solid"
                    borderColor="green"
                    style={{
                      gridAutoFlow: 'column',
                      justifyContent: 'center',
                      gridGap: '30px'
                    }}
                  >
                    {page.hypo_creditLogos.map((logo, i) => (
                      <img
                        style={{ width: 'auto', maxWidth: '100%', maxHeight: '50px' }}
                        src={logo.asset.url}
                        alt="Logo hypothecaire lening"
                      />
                    ))}
                  </Box>
                </Col>
                <Col md={6} gutter={0}>
                  <Box
                    px={3}
                    py={9}
                    backgroundColor="white"
                    display={isIE ? 'flex' : 'grid'}
                    style={{
                      gridAutoFlow: 'column',
                      justifyContent: 'center',
                      gridGap: '30px',
                      alignItems: 'center'
                    }}
                  >
                    {page.consumer_creditLogos.map((logo, i) => (
                      <img
                        style={{
                          width: 'auto',
                          maxWidth: '100%',
                          maxHeight: '50px',
                          margin: isIE ? '0 15px' : 0
                        }}
                        src={logo.asset.url}
                        alt="Logo hypothecaire lening"
                      />
                    ))}
                  </Box>
                </Col>
              </Row>
            </Breakpoint>
            <Breakpoint down="md">
              <Row gutter={0}>
                <Col xs={12} gutter={0} mb={6}>
                  <Box
                    mb={6}
                    py={6}
                    px={{ xs: 3, md: 7 }}
                    backgroundColor="primary"
                    textAlign="center"
                  >
                    <Typography color="green" variant="h1" fontSize={{ xs: 24, md: 4 }}>
                      {page.hypo_title}
                    </Typography>
                    {page.hypo_button && page.hypo_button_url && (
                      <Button
                        mt={3}
                        color="green"
                        brdrColor="green20"
                        accent="brick"
                        as="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={page.hypo_button_url}
                      >
                        {page.hypo_button}
                      </Button>
                    )}
                  </Box>
                  <Box mb={5} px={{ xs: 0, sm: 5 }}>
                    <BlockContent blocks={page._rawHypoBody || []} />
                  </Box>
                  <Box
                    py={4}
                    backgroundColor="white"
                    display={isIE ? 'flex' : 'grid'}
                    borderRight="1px solid"
                    borderColor="green"
                    gridAutoFlow={{ xs: 'row', sm: 'column' }}
                    style={{
                      justifyItems: 'center',
                      justifyContent: 'center',
                      gridGap: '30px'
                    }}
                  >
                    {page.hypo_creditLogos.map((logo, i) => (
                      <img
                        style={{ width: 'auto', maxHeight: '50px', margin: isIE ? '0 15px' : 0 }}
                        src={logo.asset.url}
                        alt="Logo hypothecaire lening"
                      />
                    ))}
                  </Box>
                </Col>
                <Col xs={12} gutter={0}>
                  <Box
                    mb={6}
                    py={6}
                    px={{ xs: 3, md: 7 }}
                    backgroundColor="primary"
                    textAlign="center"
                  >
                    <Typography color="green" variant="h1" fontSize={{ xs: 24, md: 4 }}>
                      {page.consumer_title}
                    </Typography>
                    {page.consumer_button && page.consumer_button_url && (
                      <Button
                        color="green"
                        brdrColor="green20"
                        accent="brick"
                        as="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={page.consumer_button_url}
                        mt={3}
                      >
                        {page.consumer_button}
                      </Button>
                    )}
                  </Box>
                  <Box mb={5} px={{ xs: 0, sm: 5 }}>
                    <BlockContent blocks={page._rawConsumerBody || []} />
                  </Box>
                  <Box
                    py={4}
                    backgroundColor="white"
                    display={isIE ? 'flex' : 'grid'}
                    gridAutoFlow={{ xs: 'row', sm: 'column' }}
                    style={{
                      justifyItems: 'center',
                      justifyContent: 'center',
                      gridGap: '30px'
                    }}
                  >
                    {page.consumer_creditLogos.map((logo, i) => (
                      <img
                        style={{ width: 'auto', maxHeight: '50px' }}
                        src={logo.asset.url}
                        alt="Logo hypothecaire lening"
                      />
                    ))}
                  </Box>
                </Col>
              </Row>
            </Breakpoint>
          </Grid>
        )}
      </UiContext.Consumer>
      <Contact
        formName="Kredieten"
        title={page.contact.title}
        image={page.contact.image.asset.fixed.src}
      />
    </Layout>
  );
};

export default Credit;
